import { Component, Input, OnInit } from "@angular/core";
import { HvacService } from "../../core/hvac.service";
import {
  InterfaceConfiguration,
  InterfaceCompatibility,
  HotWaterType,
} from "../../../../../codegen/out/types";

@Component({
  selector: "app-interface",
  templateUrl: "./interface.component.html",
  styleUrls: ["./interface.component.scss"],
})
export class InterfaceComponent implements OnInit {
  @Input() interfaceConfiguration: InterfaceConfiguration;

  fillColor = "#BDBDBD";

  constructor(private hvacService: HvacService) {}

  ngOnInit() {
    if (!this.interfaceConfiguration.enabled) {
      this.fillColor = "#b3b3b3";
    } else {
      switch (this.interfaceConfiguration.interface.compatibility) {
        case InterfaceCompatibility.Compatible:
          this.fillColor = "#43A047";
          break;
        case InterfaceCompatibility.CompatibleWithYellowFlag:
          this.fillColor = "#FDD835";
          break;
        case InterfaceCompatibility.Incompatible:
          this.fillColor = "#D50000";
          break;
      }
    }
  }

  getInterfaceUrl(systemInterface: any): string {
    return this.hvacService.getInterfaceUrl(systemInterface.id);
  }

  getThermInterfaceTypeUrl(tit: any): string {
    return this.hvacService.getThermInterfaceTypeUrl(tit.id);
  }

  getHotWaterLabel(hotWaterType: HotWaterType) {
    switch (hotWaterType) {
      case HotWaterType.Controllable:
        return "Controllable";
      case HotWaterType.NotSet:
        return "Not set";
      case HotWaterType.SupportsTemperature:
        return "Supports Temperature";
    }
  }
}
