import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatToolbarModule } from "@angular/material/toolbar";
import { SearchModule } from "./search/search.module";
import { GraphQLModule } from "./graphql.module";
import { HttpClientModule } from "@angular/common/http";
import { AuthModule } from "./auth/auth.module";
import { MatButtonModule } from "@angular/material/button";
import { SystemDetailModule } from "./system-detail/system-detail.module";
import { SystemResolver } from "./resolver/system.resolver";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    SearchModule,
    GraphQLModule,
    HttpClientModule,
    AuthModule,
    MatButtonModule,
    SystemDetailModule,
  ],
  providers: [SystemResolver],
  bootstrap: [AppComponent],
})
export class AppModule {}
