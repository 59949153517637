import { Component, Input, OnInit } from "@angular/core";
import { GenericSystem } from "../../../../../codegen/out/types";
import { HvacService } from "../../core/hvac.service";

@Component({
  selector: "app-picture-search-grid",
  templateUrl: "./picture-search-grid.component.html",
  styleUrls: ["./picture-search-grid.component.scss"],
})
export class PictureSearchGridComponent implements OnInit {
  @Input() systems: GenericSystem[];

  systemPictureMaxHeight: number = 150;
  zoomInEnabled: boolean = true;
  zoomOutEnabled: boolean = true;

  constructor(private hvacService: HvacService) {}

  ngOnInit(): void {}

  getSystemUrl(system: any): string {
    return this.hvacService.getSystemUrl(system.id);
  }

  getSystemImgUrl(system: GenericSystem): string {
    return system.thumbnail.schematic
      ? system.thumbnail.schematic.url
      : system.thumbnail.realLife.url;
  }

  zoomIn() {
    this.systemPictureMaxHeight = this.systemPictureMaxHeight + 50;
    if (this.systemPictureMaxHeight > 300) {
      this.zoomInEnabled = false;
    }
    this.zoomOutEnabled = true;
  }

  zoomOut() {
    this.systemPictureMaxHeight = this.systemPictureMaxHeight - 50;
    if (this.systemPictureMaxHeight <= 100) {
      this.zoomOutEnabled = false;
    }
    this.zoomInEnabled = true;
  }
}
