export const environment = {
  envName: "staging",
  production: false,
  graphqlUrl: "https://ci-ivar.tado.com/graphql",
  hvacUrl: "https://ci-hvactool.tado.com",
  amplify: {
    aws_project_region: "eu-west-1",
    aws_cognito_region: "eu-west-1",
    aws_user_pools_id: "eu-west-1_gYujnrYaT",
    aws_user_pools_web_client_id: "533bvq5g6r2tc85g7i7fk8gci",

    Auth: {
      oauth: {
        domain: "ivar-staging.auth.eu-west-1.amazoncognito.com",
        scope: ["email", "profile", "openid"],
        redirectSignIn: "https://ci-ivar.tado.com/",
        redirectSignOut: "https://ci-ivar.tado.com/",
        // 'code' for Authorization code grant,
        // 'token' for Implicit grant
        // Note that REFRESH token will only be generated when the responseType is code
        responseType: "code",
      },
    },
  },
};
