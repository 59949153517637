<div class="d-flex flex-column align-items-start">
  <mat-toolbar color="primary" class="d-flex justify-content-between">
    <a class="home-link" href="#">Ivar</a>
    <div class="toolbar-spacer flex-grow-1"></div>

    <div class="d-flex align-items-center">
      <div class="mr-4">
        <button
          mat-icon-button
          matTooltip="Open in HVAC Tool"
          (click)="openInHvac()"
        >
          <mat-icon>open_in_new</mat-icon>
        </button>
      </div>
      <app-auth-toolbar class="ml-16"></app-auth-toolbar>
    </div>
  </mat-toolbar>

  <div class="page-content">
    <div class="yellow-flag-indicator" *ngIf="hasYellowFlags()">
      <mat-icon>outlined_flag</mat-icon>
    </div>
    <div class="flex-grow-1">
      <div class="d-flex flex-row align-items-start">
        <app-system-info [system]="system"></app-system-info>

        <div class="d-flex flex-row flex-wrap">
          <mat-card class="yellow-flags ml-16 mb-16">
            <mat-card-subtitle>Yellow flags</mat-card-subtitle>

            <div *ngIf="hasYellowFlags()">
              <div *ngFor="let flag of system.yellowFlags; index as i">
                <app-full-yellow-flag
                  [yellowFlag]="flag"
                ></app-full-yellow-flag>
                <div *ngIf="showYellowFlagDivider(i)" class="mb-16">
                  <mat-divider [inset]="true"></mat-divider>
                </div>
              </div>
            </div>

            <div
              class="
                yellow-flags-empty
                d-flex
                flex-column
                align-items-center
                justify-content-center
              "
              *ngIf="!hasYellowFlags()"
            >
              <mat-icon>check_circle_outline</mat-icon>
              <span>No yellow flags</span>
            </div>
          </mat-card>

          <mat-card class="system-comment ml-16 mb-16">
            <mat-card-subtitle>System comment</mat-card-subtitle>

            <div *ngIf="hasSystemComment()">
              <span markdown>{{ system.comment }}</span>
            </div>

            <div
              class="
                system-comment-empty
                d-flex
                flex-column
                align-items-center
                justify-content-center
              "
              *ngIf="!hasSystemComment()"
            >
              <mat-icon>chat_bubble_outline</mat-icon>
              <span>No comment</span>
            </div>
          </mat-card>
          <mat-card class="system-comment ml-16 mb-16">
            <mat-card-subtitle>Attachments</mat-card-subtitle>
            <app-attachments-list
              [attachments]="system.attachments"
            ></app-attachments-list>
            <br />
            <mat-divider></mat-divider>
            <br />
            <mat-card-subtitle>Installation Instructions</mat-card-subtitle>
            <app-attachments-list
              [attachments]="system.installationInstructionsAttachments"
            ></app-attachments-list>
          </mat-card>
        </div>
      </div>

      <div class="wiring">
        <h2>Wirings</h2>
        <mat-accordion [multi]="true">
          <app-system-wiring
            *ngFor="let interfaceConfiguration of interfaceConfigurations"
            [interfaceConfiguration]="interfaceConfiguration"
          ></app-system-wiring>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
