<div class="d-flex flex-row">
  <div class="grid">
    <app-device-terminal [terminal]="terminals['N']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['L']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['D1']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['D2']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['D3']"></app-device-terminal>

    <app-system-terminal [terminal]="terminals['N']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['L']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['D1']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['D2']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['D3']"></app-system-terminal>
  </div>

  <img
    *ngIf="externalPowerSupplyNeeded"
    class="ml-16"
    src="assets/wiring-external-power-supply.svg"
    alt="External power supply needed"
    matTooltip="External power supply needed"
  />
</div>
