<div *ngIf="hasAttachments()">
  <mat-list>
    <mat-list-item
      *ngFor="let attachment of attachments"
      class="attachments-list-item"
    >
      <ng-container *ngIf="isImage(attachment)">
        <img
          (click)="openImage(attachment)"
          class="attachment-icon"
          [src]="attachment.url"
          [alt]="attachment.name"
        />
        <a
          href="{{ attachment.url }}"
          (click)="$event.preventDefault(); openImage(attachment)"
          >{{ attachment.name }}</a
        >
      </ng-container>
      <ng-container *ngIf="!isImage(attachment)">
        <mat-icon
          matListIcon
          class="attachment-icon"
          (click)="openAttachment(attachment)"
          >{{ determineIcon(attachment) }}</mat-icon
        >
        <a href="{{ attachment.url }}" target="_blank">{{ attachment.name }}</a>
      </ng-container>
    </mat-list-item>
  </mat-list>
</div>
<div
  class="
    attachment-empty
    d-flex
    flex-column
    align-items-center
    justify-content-center
  "
  *ngIf="!hasAttachments()"
>
  <mat-icon>attachment</mat-icon>
  <span>No attachments</span>
</div>
