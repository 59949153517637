<a appTooltip [tooltipContent]="tooltip" tooltipPlacement="bottom">
  <img src="{{ iconUrl }}" alt="" class="icon" />
  <span>{{ yellowFlag.title }}</span>
</a>

<div #tooltip>
  <span class="text-medium-emphasis">Type: </span>
  <span>{{ yellowFlag.type }}</span
  ><br />

  <span class="text-medium-emphasis">Applicable Devices: </span>
  <span>{{ devices }}</span
  ><br />

  <div class="mt-8">
    <span class="text-medium-emphasis">Explanation: </span>
    <span>{{ yellowFlag.explanation }}</span
    ><br />
  </div>
</div>
