<div class="d-flex text-accent">
  <app-icon
    class="mr-8"
    [icon]="icon"
    [isSelected]="true"
    [matTooltip]="iconTooltip"
  >
  </app-icon>
  <span class="font-weight-bold">{{ yellowFlag.title }}</span>
</div>

<p>
  <span markdown>{{ yellowFlag.explanation }}</span>
</p>

<div *ngIf="devices.length !== 0" class="mb-8">
  <span class="mr-8">Applies to:</span>
  <mat-chip *ngFor="let device of devices" class="mr-8">
    {{ device }}
  </mat-chip>
</div>
