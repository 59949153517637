import { Apollo, gql } from "apollo-angular";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Query } from "../../../../codegen/out/types";
import { ExtendedGenericSystem } from "src/typings/extended-generic-system";

const systemQuery = gql`
  query System($id: ID!) {
    system(id: $id) {
      __typename
      id
      modelName
      verboseNames
      comment
      manufacturers {
        name
        logo {
          schematic {
            url
          }
        }
      }
      thumbnail {
        schematic {
          url
        }
        realLife {
          url
        }
      }
      interfaceConfigurations {
        comment
        interface {
          id
          name
          compatibility
          priority
          thermInterfaceTypes {
            id
            name
            configurationMenuId
            heatingCircuitConfigurationRequired
            hotWater
          }
        }
        enabled
        connector {
          name
        }
        wiring {
          devices
          comment
          terminals {
            device
            system
            optional
          }
          jumperPosition
          bridgePosition
          externalPowerSupplyNeeded
        }
        interfaceActivation
        applicableForTbmFlow
        phyCompatibleDevices
      }
      yellowFlags {
        type
        title
        explanation
        devices {
          shortName
        }
        enabled
      }
      attachments(type: ATTACHMENT) {
        name
        url
        contentType
      }
      installationInstructionsAttachments: attachments(
        type: INSTALLATION_INSTRUCTIONS
      ) {
        name
        url
        contentType
      }
      ... on EffectiveBoilerControl {
        heatingType
        heatPumpType
      }
      ... on BoilerControl {
        heatingType
        heatPumpType
      }
      ... on BoilerControlWithFitsIn {
        heatingType
        heatPumpType
      }
    }
  }
`;

@Injectable()
export class SystemResolver implements Resolve<ExtendedGenericSystem> {
  constructor(private apollo: Apollo) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ExtendedGenericSystem> {
    const systemId = route.paramMap.get("systemId");

    return this.apollo
      .query<Query>({
        query: systemQuery,
        variables: {
          id: systemId,
        },
      })
      .pipe(
        map((res) => {
          if (res.errors != null) {
            throw Error("Query failed");
          }

          return res.data.system as ExtendedGenericSystem;
        })
      );
  }
}
