import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-collapsible-list",
  templateUrl: "./collapsible-list.component.html",
  styleUrls: ["./collapsible-list.component.scss"],
})
export class CollapsibleListComponent implements OnInit {
  @Input() list: any;
  collapsed: boolean = true;

  constructor() {}

  ngOnInit() {}

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }
}
