import { Component, Input, OnInit } from "@angular/core";
import { ExtendedGenericSystem } from "src/typings/extended-generic-system";

@Component({
  selector: "app-system-info",
  templateUrl: "./system-info.component.html",
  styleUrls: ["./system-info.component.scss"],
})
export class SystemInfoComponent implements OnInit {
  @Input() system: ExtendedGenericSystem;

  verboseNames: string;
  systemImageUrl: string;
  systemTypeImageUrl: string;
  heatingTypeInfoAvailable: boolean = false;
  heatingType: string;
  heatPumpType: string;

  constructor() {}

  ngOnInit(): void {
    this.systemTypeImageUrl = `assets/systemTypes/${this.system.__typename}.png`;

    if (this.system.verboseNames.length === 0) {
      this.verboseNames = "-";
    } else {
      this.verboseNames = this.system.verboseNames.join(", ");
    }

    if (this.system.thumbnail.realLife !== null) {
      this.systemImageUrl = this.system.thumbnail.realLife.url;
    } else {
      this.systemImageUrl = this.system.thumbnail.schematic.url;
    }

    switch (this.system.__typename) {
      case "EffectiveBoilerControl":
      case "BoilerControlWithFitsIn":
      case "BoilerControl":
        this.heatingTypeInfoAvailable = true;
        this.heatingType = this.system.heatingType;
        this.heatPumpType = this.system.heatPumpType ?? "-";
        break;
    }
  }

  openSystemImage(): void {
    window.open(this.systemImageUrl, "_blank");
  }
}
