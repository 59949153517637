<mat-card>
  <mat-card-title class="title">
    <img
      [src]="systemTypeImageUrl"
      [alt]="system.__typename"
      [matTooltip]="system.__typename"
      matTooltipPosition="below"
    />
    {{ system.modelName }}
  </mat-card-title>

  <mat-card-content class="content">
    <div class="image mr-16">
      <img
        [src]="systemImageUrl"
        (click)="openSystemImage()"
        alt="System image"
      />

      <div class="manufacturers">
        <p class="text-medium-emphasis">Manufacturers</p>
        <div class="logos">
          <mat-card
            class="logo"
            *ngFor="let manufacturer of system.manufacturers"
          >
            <mat-card-content>
              <img
                *ngIf="manufacturer.logo.schematic"
                [src]="manufacturer.logo.schematic?.url"
                [alt]="manufacturer.name"
                [matTooltip]="manufacturer.name"
                matTooltipPosition="below"
              />
              <p *ngIf="!manufacturer.logo.schematic">
                {{ manufacturer.name }}
              </p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="details">
      <div>
        <span>{{ verboseNames }}</span
        ><br />
        <span class="text-medium-emphasis">Verbose names</span>
      </div>
      <ng-container *ngIf="heatingTypeInfoAvailable">
        <div class="mt-16">
          <span>{{ heatingType }}</span
          ><br />
          <span class="text-medium-emphasis">Heating Type</span>
        </div>
        <div class="mt-16">
          <span>{{ heatPumpType }}</span
          ><br />
          <span class="text-medium-emphasis">Heat Pump Type</span>
        </div>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
