import { Component, Input, OnInit } from "@angular/core";
import { YellowFlag, YellowFlagType } from "../../../../../codegen/out/types";

@Component({
  selector: "app-full-yellow-flag",
  templateUrl: "./full-yellow-flag.component.html",
  styleUrls: ["./full-yellow-flag.component.scss"],
})
export class FullYellowFlagComponent implements OnInit {
  @Input() yellowFlag: YellowFlag;

  icon: string;
  iconTooltip: string;
  devices: string[];
  enabled: string;

  constructor() {}

  ngOnInit(): void {
    if (this.yellowFlag.type === YellowFlagType.Compatibility) {
      this.icon = "error";
      this.iconTooltip = "Compatibility Yellow Flag";
    } else {
      this.icon = "build";
      this.iconTooltip = "Installation Yellow Flag";
    }

    this.devices = this.yellowFlag.devices.map((d) => d.shortName);
    this.enabled = this.yellowFlag.enabled ? "Yes" : "No";
  }
}
