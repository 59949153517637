import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconComponent } from "./icon/icon.component";
import { CoreModule } from "../core/core.module";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [IconComponent],
  imports: [CommonModule, CoreModule, MatIconModule],
  exports: [IconComponent],
})
export class SharedModule {}
