import { Component, Input, OnInit } from "@angular/core";
import { Wiring } from "../../../../../codegen/out/types";

@Component({
  selector: "app-ru-wiring-diagram",
  templateUrl: "./ru-wiring-diagram.component.html",
  styleUrls: ["./ru-wiring-diagram.component.scss"],
})
export class RuWiringDiagramComponent implements OnInit {
  @Input() wiring: Wiring;

  terminals = {};

  ngOnInit(): void {
    for (const terminal of this.wiring.terminals) {
      this.terminals[terminal.device] = terminal;
    }
  }
}
