import { Component, Input, OnInit } from "@angular/core";
import { BridgePosition, Wiring } from "../../../../../codegen/out/types";

@Component({
  selector: "app-bp-wiring-diagram",
  templateUrl: "./bp-wiring-diagram.component.html",
  styleUrls: ["./bp-wiring-diagram.component.scss"],
})
export class BpWiringDiagramComponent implements OnInit {
  @Input() wiring: Wiring;

  terminals = {};

  externalPowerSupplyNeeded = false;
  bridgeImage: string = null;
  bridgeTooltip = "";

  ngOnInit(): void {
    for (const terminal of this.wiring.terminals) {
      this.terminals[terminal.device] = terminal;
    }

    if (this.wiring.externalPowerSupplyNeeded) {
      this.externalPowerSupplyNeeded = true;
    }

    if (this.wiring.bridgePosition === BridgePosition.LChcom) {
      this.bridgeImage = "assets/wiring-bp-bridge-l-chcom.svg";
      this.bridgeTooltip = "CH COM - L bridge must be present";
    } else if (this.wiring.bridgePosition === BridgePosition.LChcomHwcom) {
      this.bridgeImage = "assets/wiring-bp-bridge-l-chcom-hwcom.svg";
      this.bridgeTooltip =
        "CH COM - L and CH COM - HW COM bridges must be present";
    } else if (this.wiring.bridgePosition === BridgePosition.LHwcom) {
      this.bridgeImage = "assets/wiring-bp-bridge-l-hwcom.svg";
      this.bridgeTooltip = "HW COM - L bridge must be present";
    } else {
      this.bridgeImage = "assets/wiring-bp-bridge-none.svg";
      this.bridgeTooltip = "No bridge required";
    }
  }
}
