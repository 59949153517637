<div class="d-flex flex-row">
  <div class="label d-flex align-items-center mr-16">
    {{ devices }}
  </div>

  <app-bu-wiring-diagram
    class="diagram"
    *ngIf="isBoilerUnit()"
    [wiring]="wiring"
  ></app-bu-wiring-diagram>

  <app-bp-wiring-diagram
    class="diagram"
    *ngIf="isBoilerProgrammer()"
    [wiring]="wiring"
  ></app-bp-wiring-diagram>

  <app-br-wiring-diagram
    class="diagram"
    *ngIf="isBoilerReceiver()"
    [wiring]="wiring"
  ></app-br-wiring-diagram>

  <app-pr-wiring-diagram
    class="diagram"
    *ngIf="isThermostatProgrammer()"
    [wiring]="wiring"
  ></app-pr-wiring-diagram>

  <app-tr-wiring-diagram
    class="diagram"
    *ngIf="isThermostatReceiver()"
    [wiring]="wiring"
  ></app-tr-wiring-diagram>

  <app-ru-wiring-diagram
    class="diagram"
    *ngIf="isRoomUnit()"
    [wiring]="wiring"
  ></app-ru-wiring-diagram>

  <app-ru-x-wiring-diagram
    class="diagram"
    *ngIf="isRoomUnitX()"
    [wiring]="wiring"
  ></app-ru-x-wiring-diagram>

  <app-lt-wiring-diagram
    class="diagram"
    *ngIf="isLusterTerminal()"
    [wiring]="wiring"
  ></app-lt-wiring-diagram>

  <app-tc-wiring-diagram
    class="diagram"
    *ngIf="isWagoConnector()"
    [wiring]="wiring"
  ></app-tc-wiring-diagram>

  <app-ck-wiring-diagram
    class="diagram"
    *ngIf="isHeatPumpOptimizer()"
    [wiring]="wiring"
  ></app-ck-wiring-diagram>
</div>
