<div class="d-flex flex-row">
  <div class="grid">
    <app-device-terminal [terminal]="terminals['N']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['L']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['1']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['2']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['3']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['4']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['A']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['-']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['+']"></app-device-terminal>

    <app-system-terminal [terminal]="terminals['N']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['L']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['1']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['2']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['3']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['4']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['A']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['-']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['+']"></app-system-terminal>
  </div>

  <div class="ml-8">
    <img
      *ngIf="jumperImage != null"
      class="ml-8"
      src="{{ jumperImage }}"
      alt="Jumper position"
      matTooltip="Jumper position"
    />

    <img
      *ngIf="externalPowerSupplyNeeded"
      class="ml-8"
      src="assets/wiring-external-power-supply.svg"
      alt="External power supply needed"
      matTooltip="External power supply needed"
    />
  </div>
</div>
