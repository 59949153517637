import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login/login.component";
import { MatButtonModule } from "@angular/material/button";
import { ToolbarComponent } from "./toolbar/toolbar.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { InvertedStrokedButtonComponent } from "./inverted-stroked-button/inverted-stroked-button.component";

@NgModule({
  declarations: [
    LoginComponent,
    ToolbarComponent,
    InvertedStrokedButtonComponent,
  ],
  exports: [ToolbarComponent],
  imports: [CommonModule, MatButtonModule, MatToolbarModule],
})
export class AuthModule {}
