import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ExtendedGenericSystemWithAttachments } from "src/typings/extended-generic-system";
import {
  InterfaceConfiguration,
  YellowFlag,
} from "../../../../codegen/out/types";
import { HvacService } from "../core/hvac.service";

@Component({
  selector: "app-system-detail",
  templateUrl: "./system-detail.component.html",
  styleUrls: ["./system-detail.component.scss"],
})
export class SystemDetailComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private hvacService: HvacService
  ) {}

  system: ExtendedGenericSystemWithAttachments;
  interfaceConfigurations: InterfaceConfiguration[];
  yellowFlags: YellowFlag[];

  ngOnInit(): void {
    this.system = this.route.snapshot.data.system;

    this.interfaceConfigurations = [...this.system.interfaceConfigurations];
    this.interfaceConfigurations.sort(
      (a, b) => b.interface.priority - a.interface.priority
    );
    const enabledInterfaces = this.interfaceConfigurations.filter(
      (ic) => ic.enabled
    );
    const disabledInterfaces = this.interfaceConfigurations.filter(
      (ic) => !ic.enabled
    );
    this.interfaceConfigurations = enabledInterfaces.concat(disabledInterfaces);

    this.yellowFlags = this.system.yellowFlags.filter((flag) => flag.enabled);
  }

  hasYellowFlags(): boolean {
    return this.yellowFlags.length != 0;
  }

  hasSystemComment(): boolean {
    return this.system.comment != null && this.system.comment.length != 0;
  }

  showYellowFlagDivider(index: number): boolean {
    return index != this.yellowFlags.length - 1;
  }

  openInHvac(): void {
    const url = this.hvacService.getSystemUrl(this.system.id);
    window.open(url, "_blank");
  }
}
