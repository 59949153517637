<div class="container-{{ size }}">
  <mat-icon
    *ngIf="isSvg"
    svgIcon="{{ icon }}"
    [ngClass]="{ icon: true, 'icon-selected': isSelected }"
    class="icon-{{ size }}"
    [style]="iconStyle"
  ></mat-icon>

  <mat-icon
    *ngIf="!isSvg"
    [ngClass]="{ icon: true, 'icon-selected': isSelected }"
    class="icon-{{ size }}"
    [style]="iconStyle"
    >{{ icon }}</mat-icon
  >
</div>
