import { Component } from "@angular/core";
import { Hub } from "aws-amplify";
import { datadogRum } from "@datadog/browser-rum";
import { environment } from "../environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "ui";

  constructor() {
    Hub.listen("auth", (data) => {
      if (data.payload.event === "signIn") {
        // reload the page on signIn event to make content visible for authorized users
        window.location.reload();
      }
    });
    this.initDataDog();
  }

  initDataDog(): void {
    datadogRum.init({
      applicationId: "43ba6125-2c4a-491a-9862-04deb717a16d",
      clientToken: "pubfae22492173ef4bbf86aae3fcec7ce8d",
      site: "datadoghq.eu",
      service: "ivar",
      sampleRate: 100,
      trackInteractions: true,
      version: "1.0",
      env: environment.envName,
    });
  }
}
