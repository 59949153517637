import { Component, Input } from "@angular/core";
import { Attachment } from "../../../../../codegen/out/types";
import { MatDialog } from "@angular/material/dialog";
import { AttachmentsImageDialogComponent } from "./attachments-image-dialog/attachments-image-dialog.component";

@Component({
  selector: "app-attachments-list",
  templateUrl: "./attachments-list.component.html",
  styleUrls: ["./attachments-list.component.scss"],
})
export class AttachmentsListComponent {
  @Input() attachments?: Attachment[];

  constructor(public dialog: MatDialog) {}

  hasAttachments(): boolean {
    return this.attachments?.length > 0;
  }

  determineIcon(attachment: Attachment): string {
    const contentTypeParts = attachment.contentType.split(";");
    if (contentTypeParts.length > 0) {
      switch (contentTypeParts[0]) {
        case "attachment/pdf":
        case "application/pdf":
        case "application/x-pdf":
          return "picture_as_pdf";
        case "text/html":
          return "html";
        case "application/json":
          return "code";
      }
    }

    return "description";
  }

  isImage(attachment: Attachment): boolean {
    return attachment.contentType.startsWith("image/");
  }

  openImage(attachment: Attachment) {
    this.dialog.open(AttachmentsImageDialogComponent, {
      data: {
        url: attachment.url,
        name: attachment.name,
      },
      maxHeight: "100vh",
    });
  }

  openAttachment(attachment: Attachment) {
    window.open(attachment.url, "_blank");
  }
}
