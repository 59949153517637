<div
  class="cell"
  [ngClass]="[
    hasValue ? 'device-used' : 'device-unused',
    isOptional ? 'optional' : ''
  ]"
>
  <span [ngClass]="{ label: true, 'label-small': useSmallerTextSize }">
    {{ terminal.device }}
  </span>
</div>
