import { Component, Input, OnInit } from "@angular/core";
import { Wiring } from "../../../../../codegen/out/types";

@Component({
  selector: "app-tc-wiring-diagram",
  templateUrl: "./tc-wiring-diagram.component.html",
  styleUrls: ["./tc-wiring-diagram.component.scss"],
})
export class TcWiringDiagramComponent implements OnInit {
  @Input() wiring: Wiring;

  terminals = {};

  ngOnInit(): void {
    for (const terminal of this.wiring.terminals) {
      this.terminals[terminal.device] = terminal;
    }
  }
}
