import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface AttachmentsImageDialogData {
  url: string;
  name: string;
}

@Component({
  selector: "app-attachments-image-dialog",
  templateUrl: "./attachments-image-dialog.component.html",
  styleUrls: ["./attachments-image-dialog.component.scss"],
})
export class AttachmentsImageDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AttachmentsImageDialogData
  ) {}
}
