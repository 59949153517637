<mat-card class="card mat-elevation-z8">
  <mat-card-title>Filters</mat-card-title>

  <mat-form-field class="field">
    <input
      matInput
      [ngModel]="filter.searchText"
      (ngModelChange)="searchTextChanged($event)"
      placeholder="Search text"
      value=""
    />
  </mat-form-field>

  <mat-form-field class="field">
    <mat-label>Type</mat-label>
    <mat-select
      [value]="filter.type"
      (selectionChange)="filterType($event)"
      multiple
    >
      <mat-option *ngFor="let type of systemTypeList" [value]="type.value">{{
        type.label
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <app-manufacturer-select
    class="field"
    [selectedManufacturerIds]="filter.manufacturers"
    (updated)="filterManufacturers($event)"
  ></app-manufacturer-select>

  <app-tag-select
    class="field"
    title="Tags"
    [allAvailableTags]="allAvailableTags"
    [selectedTypes]="filter.type"
    [selectedTags]="filter.tags"
    [chipType]="chipTypes.INCLUDED"
    (updated)="filterTags($event)"
  ></app-tag-select>

  <app-tag-select
    class="field"
    title="Exclude Tags"
    [allAvailableTags]="allAvailableTags"
    [selectedTypes]="filter.type"
    [selectedTags]="filter.excludedTags"
    [chipType]="chipTypes.EXCLUDED"
    (updated)="filterExcludedTags($event)"
  ></app-tag-select>
</mat-card>
