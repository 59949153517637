<div class="buttons-flex">
  <button mat-icon-button (click)="zoomIn()" [disabled]="!this.zoomInEnabled">
    <mat-icon>zoom_in</mat-icon>
  </button>
  <button mat-icon-button (click)="zoomOut()" [disabled]="!this.zoomOutEnabled">
    <mat-icon>zoom_out</mat-icon>
  </button>
</div>
<div class="pictures-flex">
  <div *ngFor="let system of systems">
    <a
      href="{{ getSystemUrl(system) }}"
      target="_blank"
      appTooltip
      [tooltipContent]="systemInfo"
      tooltipPlacement="bottom"
    >
      <img
        *ngIf="system.thumbnail.schematic"
        src="{{ getSystemImgUrl(system) }}"
        alt="thumbnail"
        [ngStyle]="{
          height: systemPictureMaxHeight * 1.3 + 'px',
          'margin-bottom': systemPictureMaxHeight * -0.3 + 'px'
        }"
      />
      <img
        *ngIf="!system.thumbnail.schematic"
        src="{{ getSystemImgUrl(system) }}"
        class="real-life-thumbnail"
        alt="thumbnail"
        [ngStyle]="{ 'max-height': systemPictureMaxHeight + 'px' }"
      />
    </a>
    <div #systemInfo class="system-info">
      <ul>
        <li>ID: {{ system.id }}</li>
        <li>Model: {{ system.modelName }}</li>
      </ul>
    </div>
  </div>
</div>
