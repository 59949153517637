import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { CoreModule } from "../core/core.module";
import { FullYellowFlagComponent } from "./full-yellow-flag/full-yellow-flag.component";
import { SystemDetailComponent } from "./system-detail.component";
import { SystemWiringComponent } from "./system-wiring/system-wiring.component";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MarkdownModule } from "ngx-markdown";
import { MatTooltipModule } from "@angular/material/tooltip";
import { InterfaceComponent } from "./interface/interface.component";
import { SystemInfoComponent } from "./system-info/system-info.component";
import { MatButtonModule } from "@angular/material/button";
import { WiringDiagramComponent } from "./wiring-diagram/wiring-diagram.component";
import { BuWiringDiagramComponent } from "./bu-wiring-diagram/bu-wiring-diagram.component";
import { DeviceTerminalComponent } from "./device-terminal/device-terminal.component";
import { SystemTerminalComponent } from "./system-terminal/system-terminal.component";
import { BrWiringDiagramComponent } from "./br-wiring-diagram/br-wiring-diagram.component";
import { BpWiringDiagramComponent } from "./bp-wiring-diagram/bp-wiring-diagram.component";
import { RuWiringDiagramComponent } from "./ru-wiring-diagram/ru-wiring-diagram.component";
import { TcWiringDiagramComponent } from "./tc-wiring-diagram/tc-wiring-diagram.component";
import { LtWiringDiagramComponent } from "./lt-wiring-diagram/lt-wiring-diagram.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { AuthModule } from "../auth/auth.module";
import { SharedModule } from "../shared/shared.module";
import { TrWiringDiagramComponent } from "./tr-wiring-diagram/tr-wiring-diagram.component";
import { RuXWiringDiagramComponent } from "./ru-x-wiring-diagram/ru-x-wiring-diagram.component";
import { PrWiringDiagramComponent } from "./pr-wiring-diagram/pr-wiring-diagram.component";
import { CkWiringDiagramComponent } from "./ck-wiring-diagram/ck-wiring-diagram.component";
import { AttachmentsListComponent } from "./attachments-list/attachments-list.component";
import { MatListModule } from "@angular/material/list";
import { AttachmentsImageDialogComponent } from "./attachments-list/attachments-image-dialog/attachments-image-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [
    FullYellowFlagComponent,
    SystemInfoComponent,
    SystemDetailComponent,
    SystemWiringComponent,
    InterfaceComponent,
    WiringDiagramComponent,
    BuWiringDiagramComponent,
    DeviceTerminalComponent,
    SystemTerminalComponent,
    BrWiringDiagramComponent,
    BpWiringDiagramComponent,
    RuWiringDiagramComponent,
    TcWiringDiagramComponent,
    LtWiringDiagramComponent,
    TrWiringDiagramComponent,
    RuXWiringDiagramComponent,
    PrWiringDiagramComponent,
    CkWiringDiagramComponent,
    AttachmentsListComponent,
    AttachmentsImageDialogComponent,
  ],
  exports: [],
  imports: [
    AuthModule,
    CommonModule,
    CoreModule,
    SharedModule,
    MatCardModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    MatToolbarModule,
    MarkdownModule.forRoot(),
    MatListModule,
    MatDialogModule,
  ],
})
export class SystemDetailModule {}
