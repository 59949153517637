<div
  class="cell"
  [ngClass]="[
    hasValue ? 'system-used' : 'system-unused',
    isOptional ? 'optional' : ''
  ]"
>
  <span [ngClass]="{ label: true, 'label-small': useSmallerTextSize }">
    {{ terminal.system }}
  </span>
</div>
