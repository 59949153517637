import { gql, Apollo } from "apollo-angular";
import { Component, OnInit, ViewChild } from "@angular/core";

import { SearchSystemFilter } from "./system-filter/system-filter.component";

import { Query, GenericSystem } from "../../../../codegen/out/types";
import { MatPaginator } from "@angular/material/paginator";
import { Tag } from "../../../../codegen/out/types";
import { Router } from "@angular/router";

const searchSystemQuery = gql`
  query Systems(
    $offset: Int!
    $limit: Int!
    $searchText: String
    $systemTypes: [SystemType!]
    $manufacturerIds: [ID!]
    $tags: [String!]
    $excludedTags: [String!]
  ) {
    searchSystems(
      pagination: { offset: $offset, limit: $limit }
      searchText: $searchText
      filters: {
        systemTypes: $systemTypes
        manufacturerIds: $manufacturerIds
        tags: $tags
        excludedTags: $excludedTags
      }
    ) {
      systems {
        id
        modelName
        verboseNames
        manufacturers {
          name
        }
        thumbnail {
          schematic {
            url
          }
          realLife {
            url
          }
        }
        interfaceConfigurations {
          interface {
            id
            name
            compatibility
            thermInterfaceTypes {
              id
              name
              configurationMenuId
              heatingCircuitConfigurationRequired
              hotWater
            }
          }
          enabled
          connector {
            name
          }
          interfaceActivation
          applicableForTbmFlow
        }
        yellowFlags {
          type
          title
          explanation
          devices {
            shortName
          }
          enabled
        }
      }
      paginationInfo {
        offset
        limit
        total
      }
    }
  }
`;

const systemTagsQuery = gql`
  query {
    systemTags {
      name
      domainClass
    }
  }
`;

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  @ViewChild("paginator") paginator: MatPaginator;

  systems: any;
  systemsQuery: any;
  loading = true;
  error: any;
  limit = 5;
  pageIndex = 0;
  totalSystems = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  allAvailableTags: Tag[] = [];

  router: Router;

  constructor(private apollo: Apollo, router: Router) {
    this.router = router;
    this.loadSystemTags();
  }

  ngOnInit() {
    this.systemsQuery = this.apollo.watchQuery({
      query: searchSystemQuery,
      variables: {
        offset: this.pageIndex * this.limit,
        limit: this.limit,
        searchText: null,
      },
    });
    this.systemsQuery.valueChanges.subscribe((result) => {
      this.systems = this.parseData(result.data);
      this.totalSystems = result.data.searchSystems.paginationInfo.total;
      this.loading = result.loading;
      this.error = result.errors;
    });
  }

  loadSystemTags() {
    this.apollo
      .watchQuery({
        query: systemTagsQuery,
      })
      .valueChanges.subscribe((result) => {
        this.allAvailableTags = result.data["systemTags"];
      });
  }

  parseData(data: Query): GenericSystem[] {
    if (data == null) {
      return null;
    }

    return data.searchSystems.systems;
  }

  loadPaginatorData(event: any) {
    this.pageIndex = event.pageIndex;
    this.limit = event.pageSize;
    this.systemsQuery.refetch({
      offset: this.pageIndex * this.limit,
      limit: this.limit,
    });
  }

  loadFilterData(filter: SearchSystemFilter) {
    let searchText = null;
    if (filter.searchText !== "") {
      searchText = filter.searchText;
    }

    let systemTypes = null;
    if (filter.type.length > 0) {
      systemTypes = filter.type;
    }

    let manufacturers = null;
    if (filter.manufacturers.length > 0) {
      manufacturers = filter.manufacturers;
    }

    let tags = null;
    if (filter.tags.length > 0) {
      tags = filter.tags;
    }

    let excludedTags = null;
    if (filter.excludedTags.length > 0) {
      excludedTags = filter.excludedTags;
    }
    this.systemsQuery.refetch({
      searchText: searchText,
      systemTypes: systemTypes,
      manufacturerIds: manufacturers,
      tags: tags,
      excludedTags: excludedTags,
    });
    this.paginator?.firstPage();
  }
}
