<div class="d-flex flex-row">
  <div class="grid">
    <app-device-terminal [terminal]="terminals['N']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['L']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['CH COM']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['CH NC']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['CH NO']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['HW COM']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['HW NC']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['HW NO']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['⏚']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['P1']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['P2']"></app-device-terminal>

    <app-system-terminal [terminal]="terminals['N']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['L']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['CH COM']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['CH NC']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['CH NO']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['HW COM']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['HW NC']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['HW NO']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['⏚']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['P1']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['P2']"></app-system-terminal>
  </div>

  <div class="ml-8">
    <img
      class="ml-8"
      src="{{ bridgeImage }}"
      alt="Bridge position"
      matTooltip="{{ bridgeTooltip }}"
    />

    <img
      *ngIf="externalPowerSupplyNeeded"
      class="ml-8"
      src="assets/wiring-external-power-supply.svg"
      alt="External power supply needed"
      matTooltip="External power supply needed"
    />
  </div>
</div>
