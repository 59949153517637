import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { ChipType } from "../tag-select/chip-type.enum";
import { Tag } from "../../../../../codegen/out/types";

export interface SearchSystemFilter {
  searchText?: string;
  type: string[];
  manufacturers: number[];
  tags: string[];
  excludedTags: string[];
}

export let SystemTypes = [
  {
    value: "RoomThermostat",
    label: "RTs",
    domainClass: "com.tado.hvac.RoomThermostat",
  },
  {
    value: "RfRoomThermostat",
    label: "RF-RTs",
    domainClass: "com.tado.hvac.GenericSystem",
  },

  {
    value: "Remote",
    label: "Remotes",
    domainClass: "com.tado.hvac.GenericSystem",
  },
  {
    value: "RfRemote",
    label: "RF-Remotes",
    domainClass: "com.tado.hvac.GenericSystem",
  },

  {
    value: "RoomThermostatReceiver",
    label: "Receivers",
    domainClass: "com.tado.hvac.GenericSystem",
  },
  {
    value: "AuxiliaryControl",
    label: "Aux Controls",
    domainClass: "com.tado.hvac.GenericSystem",
  },
  {
    value: "TranslationModule",
    label: "Trans. Modules",
    domainClass: "com.tado.hvac.GenericSystem",
  },
  {
    value: "BoilerControl",
    label: "BCs",
    domainClass: "com.tado.hvac.GenericSystem",
  },
  {
    value: "BoilerControlWithFitsIn",
    label: "BCs with fitted in controller",
    domainClass: "com.tado.hvac.GenericSystem",
  },
  {
    value: "Boiler",
    label: "Boilers",
    domainClass: "com.tado.hvac.GenericSystem",
  },
  {
    value: "RadiatorValve",
    label: "Radiator valves",
    domainClass: "com.tado.hvac.RadiatorValve",
  },
  {
    value: "RadiatorThermostat",
    label: "Radiator thermostats",
    domainClass: "com.tado.hvac.RadiatorThermostat",
  },
  {
    value: "Furnace",
    label: "Furnaces",
    domainClass: "com.tado.hvac.GenericSystem",
  },
  {
    value: "RadiatorValveAdaptor",
    label: "Radiator valve adaptor",
    domainClass: "com.tado.hvac.RadiatorValveAdaptor",
  },
  {
    value: "ControlBoard",
    label: "Control Boards",
    domainClass: "com.tado.hvac.GenericSystem",
  },
  {
    value: "SketchSystem",
    label: "Sketches",
    domainClass: "com.tado.hvac.GenericSystem",
  },
  {
    value: "AcIndoorUnit",
    label: "AC Indoor Unit",
    domainClass: "com.tado.hvac.GenericSystem",
  },
  {
    value: "AcRemote",
    label: "AC Remote",
    domainClass: "com.tado.hvac.AcRemote",
  },
];

@Component({
  selector: "app-system-filter",
  templateUrl: "./system-filter.component.html",
  styleUrls: ["./system-filter.component.scss"],
})
export class SystemFilterComponent implements OnInit {
  chipTypes = ChipType;
  filter: SearchSystemFilter = {
    searchText: null,
    type: [],
    manufacturers: [],
    tags: [],
    excludedTags: [],
  };

  @Input() allAvailableTags: Tag[];

  @Output() updated = new EventEmitter<SearchSystemFilter>();

  systemTypeList = SystemTypes;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.initFilterValues();
  }

  filterType(event: MatSelectChange) {
    this.filter.type = event.value;
    this.triggerUpdate();
  }

  filterManufacturers(manufacturers: any) {
    this.filter.manufacturers = manufacturers;
    this.triggerUpdate();
  }

  filterTags(tags: string[]) {
    this.filter.tags = tags;
    this.triggerUpdate();
  }

  filterExcludedTags(tags: string[]) {
    this.filter.excludedTags = tags;
    this.triggerUpdate();
  }

  searchTextChanged(value: string) {
    this.filter.searchText = value;
    this.triggerUpdate();
  }

  private triggerUpdate() {
    this.updated.emit(this.filter);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.prepareFilterParams(),
      queryParamsHandling: "merge",
    });
  }

  private prepareFilterParams() {
    let filters = {};
    _.keys(this.filter).forEach((filterName: string) => {
      if (
        this.filter[filterName] === null ||
        this.filter[filterName].length === 0
      ) {
        filters[filterName] = null;
      } else {
        filters[filterName] = this.filter[filterName];
      }
    });

    return filters;
  }

  private initFilterValues() {
    this.route.queryParams.subscribe((params) => {
      let hasFilterValuesInParams = false;
      _.keys(this.filter).forEach((filterName: string) => {
        if (params[filterName] != null) {
          this.updateFilterValue(filterName, params[filterName]);
          hasFilterValuesInParams = true;
        }
      });
      if (hasFilterValuesInParams) {
        this.triggerUpdate();
      }
    });
  }

  private updateFilterValue(filterName: string, value: any) {
    if (Array.isArray(this.filter[filterName])) {
      this.filter[filterName] = Array.isArray(value) ? value : [value];
    } else if (Array.isArray(value)) {
      // something wrong with input, array is not expected here, just ignore it
    } else {
      this.filter[filterName] = value;
    }
  }
}
