import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import tippy, { Content, Placement } from "tippy.js";

@Directive({
  selector: "[appTooltip]",
})
export class TooltipDirective implements OnInit {
  @Input() tooltipContent: Content = "";
  @Input() tooltipPlacement: Placement = "auto";
  @Input() tooltipInteractive: boolean = true;

  constructor(private element: ElementRef) {}

  ngOnInit() {
    tippy(this.element.nativeElement, {
      allowHTML: true,
      theme: "light",
      interactive: this.tooltipInteractive,
      content: this.tooltipContent,
      placement: this.tooltipPlacement,
      appendTo: document.body,
    });
  }
}
