<div>
  <div *ngFor="let item of list; let i = index" [attr.data-index]="i">
    <div class="spaced-list-element" *ngIf="i < 3">
      {{ item }}
    </div>
    <div *ngIf="!collapsed">
      <div class="spaced-list-element" *ngIf="i >= 3">
        {{ item }}
      </div>
    </div>
  </div>

  <div class="spaced-list-element" *ngIf="list.length > 3">
    <a [routerLink]="" (click)="toggleCollapsed()">
      {{ collapsed == true ? "Show more" : "Show less" }}
    </a>
  </div>
</div>
