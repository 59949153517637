import { Component, Input, OnInit } from "@angular/core";
import { YellowFlag, YellowFlagType } from "../../../../../codegen/out/types";

@Component({
  selector: "app-yellow-flag",
  templateUrl: "./yellow-flag.component.html",
  styleUrls: ["./yellow-flag.component.scss"],
})
export class YellowFlagComponent implements OnInit {
  @Input() yellowFlag: YellowFlag;

  iconUrl: string;
  devices: string;
  enabled: string;

  constructor() {}

  ngOnInit() {
    if (this.yellowFlag.type === YellowFlagType.Compatibility) {
      this.iconUrl = "assets/icons/error-24.svg";
    } else {
      this.iconUrl = "assets/icons/build-24.svg";
    }

    this.devices = this.yellowFlag.devices.map((d) => d.shortName).join(", ");
    this.enabled = this.yellowFlag.enabled ? "Yes" : "No";
  }
}
