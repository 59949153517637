<mat-form-field>
  <mat-label>Manufacturer</mat-label>
  <mat-chip-list #chipList aria-label="Manufacturer selection">
    <mat-chip
      *ngFor="let manufacturer of selectedManufacturers"
      [removable]="removable"
      (removed)="remove(manufacturer)"
    >
      {{ manufacturer.name }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      placeholder="Manufacturer"
      #manufacturerInput
      [formControl]="manufacturerCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option
      *ngFor="let manufacturer of filteredManufacturers | async"
      [value]="manufacturer"
    >
      {{ manufacturer.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
