<svg class="compatibility-icon">
  <circle cx="8" cy="8" r="8" [attr.fill]="fillColor" />
</svg>
<a
  mat-button
  color="primary"
  href="{{ getInterfaceUrl(interfaceConfiguration.interface) }}"
  target="_blank"
  appTooltip
  [tooltipContent]="tooltip"
  tooltipPlacement="bottom"
  >{{ interfaceConfiguration.interface.name }}</a
>
<div #tooltip>
  <ng-container *ngIf="interfaceConfiguration.connector?.name"
    ><span class="text-medium-emphasis">Connector:</span>
    {{ interfaceConfiguration.connector?.name }}<br
  /></ng-container>
  <ng-container *ngIf="interfaceConfiguration.interfaceActivation"
    ><span class="text-medium-emphasis">Activation:</span>
    {{ interfaceConfiguration.interfaceActivation }}<br
  /></ng-container>
  <span class="text-medium-emphasis">Applicable for Generic flow:</span>
  {{ interfaceConfiguration.applicableForTbmFlow }}<br />

  <div *ngIf="interfaceConfiguration.interface.thermInterfaceTypes">
    <div
      *ngFor="
        let thermInterfaceType of interfaceConfiguration.interface
          .thermInterfaceTypes
      "
    >
      <hr />
      <span class="text-medium-emphasis">Therm Interface Type: </span>
      <a
        href="{{ getThermInterfaceTypeUrl(thermInterfaceType) }}"
        target="_blank"
        >{{ thermInterfaceType.name }}</a
      >
      <br />
      <span class="text-medium-emphasis">Hot Water:</span>
      {{ getHotWaterLabel(thermInterfaceType.hotWater) }}<br />
      <div *ngIf="thermInterfaceType.configurationMenuId">
        <span class="text-medium-emphasis">Configuration Menu Id:</span>
        {{ thermInterfaceType.configurationMenuId }}<br />
      </div>
    </div>
  </div>
</div>
