import { gql, Apollo } from "apollo-angular";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { Manufacturer, Query } from "../../../../../codegen/out/types";
import { FormControl } from "@angular/forms";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Observable, Subscription } from "rxjs";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from "@angular/material/autocomplete";
import { map, startWith } from "rxjs/operators";
import { MatChipInputEvent } from "@angular/material/chips";
import { unsubscribe } from "src/app/core/subscription.helper";

const searchManufacturersQuery = gql`
  query {
    searchManufacturers {
      manufacturers {
        name
        id
      }
    }
  }
`;

@Component({
  selector: "app-manufacturer-select",
  templateUrl: "./manufacturer-select.component.html",
  styleUrls: ["./manufacturer-select.component.scss"],
})
export class ManufacturerSelectComponent implements OnInit {
  subscriptions: { [key: string]: Subscription } = {};
  removable = true;
  manufacturers: Manufacturer[];
  filteredManufacturers: Observable<Manufacturer[]>;
  manufacturersQuery: any;
  manufacturerCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectedManufacturers: Manufacturer[] = [];

  @ViewChild("manufacturerInput")
  manufacturerInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  @Input() selectedManufacturerIds = [];
  @Output() updated = new EventEmitter<number[]>();

  constructor(private apollo: Apollo) {}

  ngOnInit() {
    this.manufacturersQuery = this.apollo.query({
      query: searchManufacturersQuery,
    });

    this.subscriptions.manufacturers = this.manufacturersQuery.subscribe(
      (result) => {
        this.manufacturers = this.parseQueryResult(result.data);
        this.filteredManufacturers = this.manufacturerCtrl.valueChanges.pipe(
          startWith(""),
          map((value) => (typeof value === "string" ? value : value.name)),
          map((name) => (name ? this.filter(name) : this.manufacturers.slice()))
        );
        this.selectedManufacturers = this.manufacturers.filter(
          (manufacturer) => {
            return this.selectedManufacturerIds.indexOf(manufacturer.id) !== -1;
          }
        );
      }
    );
  }

  ngOnDestroy() {
    unsubscribe(this.subscriptions);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    const selected = this.manufacturers.find(
      (manufacturer) => manufacturer.name === value
    );
    if (selected) {
      this.selectedManufacturers.push(selected);
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
    this.manufacturerCtrl.setValue("");
  }

  remove(manufacturerInput: Manufacturer): void {
    const index = this.selectedManufacturers.findIndex(
      (manufacturer) => manufacturer.id === manufacturerInput.id
    );
    this.selectedManufacturers.splice(index, 1);
    this.updated.emit(
      this.selectedManufacturers.map((manufacturer) =>
        Number.parseInt(manufacturer.id)
      )
    );
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedManufacturers.push(event.option.value);
    this.manufacturerInput.nativeElement.value = "";
    this.manufacturerCtrl.setValue("");
    this.updated.emit(
      this.selectedManufacturers.map((manufacturer) =>
        Number.parseInt(manufacturer.id)
      )
    );
  }

  private filter(value: string): Manufacturer[] {
    const filterValue = value.toLowerCase();

    return this.manufacturers.filter(
      (manufacturer) =>
        manufacturer.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private parseQueryResult(data: Query): Manufacturer[] {
    if (data == null) {
      return [
        {
          name: "...",
          id: "-1",
        } as Manufacturer,
      ];
    }

    return data.searchManufacturers.manufacturers;
  }
}
