import { Component, Input, OnInit } from "@angular/core";
import { Wiring } from "../../../../../codegen/out/types";

@Component({
  selector: "app-wiring-diagram",
  templateUrl: "./wiring-diagram.component.html",
  styleUrls: ["./wiring-diagram.component.scss"],
})
export class WiringDiagramComponent implements OnInit {
  @Input() wiring: Wiring;

  devices = "";

  constructor() {}

  ngOnInit(): void {
    this.devices = this.wiring.devices.join(" / ");
  }

  isBoilerUnit(): boolean {
    return this.wiring.devices.some((d) => ["BU01", "BU02"].includes(d));
  }

  isBoilerProgrammer(): boolean {
    return this.wiring.devices.some((d) => d === "BP02");
  }

  isBoilerReceiver(): boolean {
    return this.wiring.devices.some((d) => d === "BR02");
  }

  isThermostatProgrammer(): boolean {
    return this.wiring.devices.some((d) => d === "PR04");
  }

  isThermostatReceiver(): boolean {
    return this.wiring.devices.some((d) => d === "TR04");
  }

  isRoomUnit(): boolean {
    return this.wiring.devices.some((d) =>
      ["RU01", "RU02", "RU02B"].includes(d)
    );
  }

  isRoomUnitX(): boolean {
    return this.wiring.devices.some((d) => d === "RU04");
  }

  isLusterTerminal(): boolean {
    return this.wiring.devices.some((d) => d === "LT01");
  }

  isWagoConnector(): boolean {
    return this.wiring.devices.some((d) => d === "TC01");
  }

  isHeatPumpOptimizer(): boolean {
    return this.wiring.devices.some((d) => d === "CK04");
  }
}
