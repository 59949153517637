import { Component, Input, OnInit } from "@angular/core";
import {
  InterfaceConfiguration,
  Wiring,
} from "../../../../../codegen/out/types";

@Component({
  selector: "app-system-wiring",
  templateUrl: "./system-wiring.component.html",
  styleUrls: ["./system-wiring.component.scss"],
})
export class SystemWiringComponent implements OnInit {
  @Input() interfaceConfiguration: InterfaceConfiguration;

  devices: string[];
  wirings: Wiring[];
  devicesWithWiring = new Set();
  connectorName: string;
  interfaceActivation: string;
  hasComment: boolean;

  constructor() {}

  ngOnInit(): void {
    const legacyDevices = ["BY01", "BX01", "BX02"];
    this.wirings = this.interfaceConfiguration.wiring.filter(
      (wiring) =>
        !wiring.devices.some((device) => legacyDevices.includes(device))
    );

    this.wirings.forEach((wiring) => {
      wiring.devices.forEach((device) => this.devicesWithWiring.add(device));
    });

    this.devices = this.interfaceConfiguration.phyCompatibleDevices.filter(
      (device) => !legacyDevices.includes(device)
    );

    this.connectorName = this.interfaceConfiguration.connector.name;
    this.interfaceActivation = this.getInterfaceActivationLabel(
      this.interfaceConfiguration.interfaceActivation
    );

    this.hasComment =
      this.interfaceConfiguration.comment != null &&
      this.interfaceConfiguration.comment.length != 0;
  }

  deviceAvatar(device: string): string {
    if (this.hasWiring(device)) {
      return "cable";
    } else {
      return "unpublished";
    }
  }

  hasWiring(device: string): boolean {
    return this.devicesWithWiring.has(device);
  }

  hasWiringComment(wiring: Wiring): boolean {
    return wiring.comment != null && wiring.comment.length != 0;
  }

  getInterfaceActivationLabel(interfaceActivation: string): string {
    switch (interfaceActivation) {
      case "BC_RELAY_BRIDGED_REQUIRED":
        return "BC relay needs to be bridged";
      case "BC_RELAY_BRIDGED_POSSIBLE":
        return "BC relay may not be bridged";
      case "NONE":
        return "None";
      case "UNKNOWN":
        return "Unknown";
      case "OTHER":
        return "Other (see comment)";
      default:
        return "-";
    }
  }
}
