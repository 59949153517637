<table mat-table [dataSource]="systems">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let system">
      <a
        mat-button
        color="primary"
        title="Open system"
        [routerLink]="['/systems', system.id]"
      >
        {{ system.id }}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="manufacturers">
    <th mat-header-cell *matHeaderCellDef class="align-right">Manufacturers</th>
    <td mat-cell *matCellDef="let system" class="align-right">
      <div
        *ngFor="let manufacturer of system.manufacturers"
        class="spaced-list-element"
      >
        {{ manufacturer.name }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="thumbnail">
    <th mat-header-cell *matHeaderCellDef class="align-right">Image</th>
    <td mat-cell *matCellDef="let system" class="align-right">
      <div
        class="thumbnail-wrapper"
        appTooltip
        [tooltipContent]="thumbnailMagnifier"
        tooltipPlacement="bottom"
      >
        <img
          *ngIf="system.thumbnail.schematic"
          mat-card-lg-image
          src="{{ system.thumbnail.schematic.url }}"
          alt="thumbnail"
          class="thumbnail"
        />
      </div>

      <div #thumbnailMagnifier>
        <img
          *ngIf="system.thumbnail.schematic"
          mat-card-lg-image
          src="{{ system.thumbnail.schematic.url }}"
          alt="System image"
          class="thumbnail-magnifier"
        />
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="modelName">
    <th mat-header-cell *matHeaderCellDef class="align-right">Model</th>
    <td mat-cell *matCellDef="let system" class="align-right">
      {{ system.modelName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="verboseNames">
    <th mat-header-cell *matHeaderCellDef class="align-right">Verbose Names</th>
    <td mat-cell *matCellDef="let system">
      <app-collapsible-list [list]="system.verboseNames"></app-collapsible-list>
    </td>
  </ng-container>

  <ng-container matColumnDef="interfaces">
    <th mat-header-cell *matHeaderCellDef class="align-right">Interfaces</th>
    <td mat-cell *matCellDef="let system" class="align-right">
      <app-interface
        *ngFor="let interfaceConfiguration of system.interfaceConfigurations"
        [interfaceConfiguration]="interfaceConfiguration"
      ></app-interface>
    </td>
  </ng-container>

  <ng-container matColumnDef="yellowFlags">
    <th mat-header-cell *matHeaderCellDef class="align-right">Flags</th>
    <td mat-cell *matCellDef="let system" class="align-right">
      <p *ngFor="let yellowFlag of system.yellowFlags">
        <app-yellow-flag
          [yellowFlag]="yellowFlag"
          *ngIf="yellowFlag.enabled"
        ></app-yellow-flag>
      </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let system">
      <a
        mat-icon-button
        title="Open system"
        [routerLink]="['/systems', system.id]"
      >
        <mat-icon>search</mat-icon>
      </a>
      <a
        mat-icon-button
        title="Open in HVAC Tool"
        href="{{ getSystemUrl(system) }}"
        target="_blank"
      >
        <mat-icon>open_in_new</mat-icon>
      </a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let whydoesthiswork; columns: columnsToDisplay"></tr>
</table>
