import { Subscription } from "rxjs";

export const unsubscribe = (subscriptions: {
  [key: string]: Subscription;
}): void => {
  Object.keys(subscriptions).forEach((key) => {
    if (subscriptions[key]) {
      subscriptions[key].unsubscribe();
      delete subscriptions[key];
    }
  });
};
