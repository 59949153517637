import { Component, Input, OnInit } from "@angular/core";
import { Wiring } from "../../../../../codegen/out/types";

@Component({
  selector: "app-ck-wiring-diagram",
  templateUrl: "./ck-wiring-diagram.component.html",
  styleUrls: ["./ck-wiring-diagram.component.scss"],
})
export class CkWiringDiagramComponent implements OnInit {
  @Input() wiring: Wiring;

  terminals = {};

  externalPowerSupplyNeeded = false;

  ngOnInit(): void {
    for (const terminal of this.wiring.terminals) {
      this.terminals[terminal.device] = terminal;
    }

    if (this.wiring.externalPowerSupplyNeeded) {
      this.externalPowerSupplyNeeded = true;
    }
  }
}
