<div class="d-flex flex-row">
  <div class="grid">
    <app-device-terminal [terminal]="terminals['NO']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['NC']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['COM']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['P1']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['P2']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['P3']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['A']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['-']"></app-device-terminal>
    <app-device-terminal [terminal]="terminals['+']"></app-device-terminal>

    <app-system-terminal [terminal]="terminals['NO']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['NC']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['COM']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['P1']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['P2']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['P3']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['A']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['-']"></app-system-terminal>
    <app-system-terminal [terminal]="terminals['+']"></app-system-terminal>
  </div>
</div>
