import { Component, OnInit } from "@angular/core";
import { Auth, Hub } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { datadogRum } from "@datadog/browser-rum";
import { MDC } from "../../core/mdc.model";

@Component({
  selector: "app-auth-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
})
export class ToolbarComponent implements OnInit {
  private datadogRumWrapper: typeof datadogRum = datadogRum;

  user: CognitoUser = null;
  mdc: MDC;

  constructor() {}

  async ngOnInit() {
    this.updateCurrentUser();
    Hub.listen("auth", (data) => {
      this.updateCurrentUser();
    });
  }

  async updateCurrentUser() {
    try {
      this.user = await Auth.currentAuthenticatedUser();
      this.mdc = {
        username: this.user.getSignInUserSession().getIdToken()?.payload?.name,
        email: this.user.getSignInUserSession().getIdToken()?.payload?.email,
      };
    } catch (e) {
      this.mdc = null;
      this.user = null;
    }
    this.datadogRumWrapper.addRumGlobalContext("mdc", this.mdc as any);
  }

  async signInWithGoogle() {
    const options = {
      provider: "Google",
    };

    // @ts-ignore
    await Auth.federatedSignIn(options);
  }

  isAuthorized() {
    return this.user !== null;
  }

  async signOut() {
    await Auth.signOut();
  }
}
