import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SearchComponent } from "./search.component";
import { SystemFilterComponent } from "./system-filter/system-filter.component";
import { SystemGridComponent } from "./system-grid/system-grid.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatCardModule } from "@angular/material/card";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { YellowFlagComponent } from "./yellow-flag/yellow-flag.component";
import { ManufacturerSelectComponent } from "./manufacturer-select/manufacturer-select.component";
import { MatChipsModule } from "@angular/material/chips";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIconModule } from "@angular/material/icon";
import { CollapsibleListComponent } from "./collapsible-list/collapsible-list.component";
import { RouterModule } from "@angular/router";
import { PictureSearchGridComponent } from "./picture-search-grid/picture-search-grid.component";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTabsModule } from "@angular/material/tabs";
import { TagSelectComponent } from "./tag-select/tag-select.component";
import { CoreModule } from "../core/core.module";
import { InterfaceComponent } from "./interface/interface.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { AuthModule } from "../auth/auth.module";

@NgModule({
  declarations: [
    SearchComponent,
    SystemFilterComponent,
    SystemGridComponent,
    YellowFlagComponent,
    ManufacturerSelectComponent,
    CollapsibleListComponent,
    PictureSearchGridComponent,
    TagSelectComponent,
    InterfaceComponent,
  ],
  exports: [SearchComponent],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatCardModule,
    MatGridListModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatButtonModule,
    MatTooltipModule,
    MatTabsModule,
    MatToolbarModule,
    CoreModule,
    AuthModule,
  ],
})
export class SearchModule {}
