import { Component, Input, OnInit } from "@angular/core";
import { HvacService } from "../../core/hvac.service";
import { GenericSystem, Interface } from "../../../../../codegen/out/types";

@Component({
  selector: "app-system-grid",
  templateUrl: "./system-grid.component.html",
  styleUrls: ["./system-grid.component.scss"],
})
export class SystemGridComponent implements OnInit {
  @Input() systems: GenericSystem[];

  columnsToDisplay = [
    "id",
    "manufacturers",
    "thumbnail",
    "modelName",
    "verboseNames",
    "interfaces",
    "yellowFlags",
    "actions",
  ];

  constructor(private hvacService: HvacService) {}

  ngOnInit() {}

  getSystemUrl(system: any): string {
    return this.hvacService.getSystemUrl(system.id);
  }
}
