import { Component, Input, OnInit } from "@angular/core";
import { IconService } from "../../core/icon.service";

@Component({
  selector: "app-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
})
export class IconComponent implements OnInit {
  @Input() icon: string;
  @Input() isSelected = false;
  @Input() size: number = 16;
  @Input() iconStyle: string;
  isSvg = false;

  constructor(private iconService: IconService) {}

  ngOnInit(): void {
    this.isSvg = this.iconService.svgIcons.has(this.icon);
  }
}
