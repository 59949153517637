<mat-form-field>
  <mat-label>{{ title }}</mat-label>
  <mat-chip-list #chipList aria-label="Tag selection">
    <mat-chip
      [ngClass]="bgClass"
      *ngFor="let tag of selectedTags"
      [removable]="true"
      (removed)="remove(tag)"
    >
      {{ tag }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      placeholder="Tag"
      matInput
      #tagInput
      [formControl]="formControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
      {{ tag }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
