import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class HvacService {
  private baseUrl = environment.hvacUrl;

  constructor() {}

  getSystemUrl(id: string): string {
    return `${this.baseUrl}/genericSystem/show/${id}`;
  }

  getInterfaceUrl(id: string): string {
    return `${this.baseUrl}/interface/show/${id}`;
  }
  getThermInterfaceTypeUrl(id: string): string {
    return `${this.baseUrl}/thermInterfaceType/show/${id}`;
  }
}
