<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <app-interface
        [interfaceConfiguration]="interfaceConfiguration"
      ></app-interface>
      <mat-chip-list>
        <mat-chip
          *ngFor="let device of devices"
          [disabled]="!hasWiring(device)"
        >
          <mat-icon matChipAvatar>{{ deviceAvatar(device) }}</mat-icon>
          {{ device }}
        </mat-chip>
      </mat-chip-list>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div
    class="d-flex flex-column"
    [ngClass]="{ disabled: !interfaceConfiguration.enabled }"
  >
    <div class="details mt-8 mb-16 d-flex flex-row">
      <div class="mr-32">
        <span>{{ connectorName }}</span
        ><br />
        <span class="text-medium-emphasis">Connector Name</span>
      </div>
      <div>
        <div class="d-flex align-items-center">
          <span>{{ interfaceActivation }}</span>
          <mat-icon
            class="comment-icon ml-8"
            *ngIf="hasComment"
            matTooltip="Interface comment available"
            >comment
          </mat-icon>
        </div>
        <span class="text-medium-emphasis">Interface Activation</span>
      </div>
    </div>

    <div class="mt-16" *ngFor="let wiring of wirings; let last = last">
      <app-wiring-diagram class="wiring" [wiring]="wiring">
      </app-wiring-diagram>
      <div class="wiring-comment mt-4" [ngClass]="{ 'mb-16': !last }">
        <div
          *ngIf="hasWiringComment(wiring)"
          class="d-flex align-items-center text-accent font-weight-bold"
        >
          <mat-icon class="mr-8">info</mat-icon>
          <span>{{ wiring.comment }}</span>
        </div>
      </div>
      <mat-divider *ngIf="!last"></mat-divider>
    </div>
  </div>

  <mat-action-row>
    <button mat-button [disabled]="true" color="primary">View Comment</button>
    <button mat-button [disabled]="true" color="primary">Show Connector</button>
  </mat-action-row>
</mat-expansion-panel>
