<div class="d-flex flex-column align-items-start">
  <mat-toolbar color="primary" class="d-flex justify-content-between">
    <a class="home-link" href="#">Ivar</a>
    <div class="toolbar-spacer flex-grow-1"></div>
    <app-auth-toolbar></app-auth-toolbar>
  </mat-toolbar>

  <div class="page-content d-flex flex-grow-1">
    <app-system-filter
      (updated)="loadFilterData($event)"
      [allAvailableTags]="allAvailableTags"
    ></app-system-filter>
    <mat-card class="content mat-elevation-z8">
      <mat-tab-group dynamicHeight>
        <mat-tab label="Table View" class="non-scroll">
          <app-system-grid [systems]="systems"> </app-system-grid>
        </mat-tab>
        <mat-tab label="Picture Search">
          <app-picture-search-grid [systems]="systems">
          </app-picture-search-grid>
        </mat-tab>
      </mat-tab-group>

      <mat-paginator
        #paginator
        [pageSize]="limit"
        [pageIndex]="pageIndex"
        [length]="totalSystems"
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons
        (page)="loadPaginatorData($event)"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
