import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";
import { LoginComponent } from "./auth/login/login.component";
import { SearchComponent } from "./search/search.component";
import { UnauthGuard } from "./auth/unauth.guard";
import { SystemDetailComponent } from "./system-detail/system-detail.component";
import { SystemResolver } from "./resolver/system.resolver";

const routes: Routes = [
  {
    path: "",
    component: SearchComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "login",
    component: LoginComponent,
    pathMatch: "full",
    canActivate: [UnauthGuard],
  },
  {
    path: "systems/:systemId",
    component: SystemDetailComponent,
    canActivate: [AuthGuard],
    resolve: {
      system: SystemResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
