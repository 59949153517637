import { Component, Input, OnInit } from "@angular/core";
import { Terminal } from "../../../../../codegen/out/types";

@Component({
  selector: "app-system-terminal",
  templateUrl: "./system-terminal.component.html",
  styleUrls: ["./system-terminal.component.scss"],
})
export class SystemTerminalComponent implements OnInit {
  @Input() terminal: Terminal;

  hasValue: boolean;
  isOptional: boolean;
  useSmallerTextSize = false;

  ngOnInit(): void {
    this.hasValue =
      this.terminal.system !== null && this.terminal.system !== "";

    this.isOptional = this.terminal.optional;

    if (this.hasValue) {
      this.useSmallerTextSize = this.terminal.system.length > 6;
    }
  }
}
